@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&family=Nunito:ital,wght@0,300;1,200&display=swap');

body {
  font-family: 'Nunito', sans-serif !important;
}

.prop-nax-header {
  filter: drop-shadow(0 0 17.5px rgba(0, 0, 0, 0.1)) !important;

}

.propnx-carousel.propnx-carousel img {
  height: 400px;
  object-fit: cover;
}

.home-page {
  height: 100%;
}

.all-properties {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.prop-nax-header .logo {
  width: 200px;
  height: 50px;
}

.property-card.card {
  cursor: pointer;
  width: 100%;
  max-width: 320px;
  border-radius: 15px !important;
  height: 100%;
  max-height: 400px;
  overflow: hidden;
}

.property-card.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
  width: 100%;
  height: 170px;
}

.property-card .carousel-indicators>button,
.propnx-carousel .carousel-indicators>button,
.prop-model .carousel-indicators>button {
  border-radius: 50%;
  height: 5px;
  width: 5px;
  opacity: 0.6;
  transition: all 0.3s;
}

.property-card .carousel-indicators>button.active,
.propnx-carousel .carousel-indicators>button.active,
.prop-model .carousel-indicators>button.active {
  transform: scale(1.6);
  opacity: 1;
}

.prop-details small {
  font-size: 12px;
  display: block;
  vertical-align: middle !important;
}

.property-card .carousel-control-prev,
.prop-model .carousel-control-prev {
  opacity: 0;
  background-image: linear-gradient(90deg, #00000047, transparent);
  background-size: auto !important;
}

.property-card .carousel-control-next,
.prop-model .carousel-control-next {
  opacity: 0;
  background-image: linear-gradient(90deg, transparent, #00000047);
  background-size: auto !important;
}

.carousel-control-next, .carousel-control-prev {
  background-size: 15px 15px !important;
  transition: all 0.3s;
}

.propnx-carousel:hover .carousel-control-next, .propnx-carousel:hover .carousel-control-prev {
  opacity: 1 !important;
}



.visited-property:hover .carousel-control-next, .visited-property:hover .carousel-control-prev {
  opacity: 1;
}

.visited-property .carousel-caption {
  top: 0;
  bottom: auto;
  text-align: left;
}


.visited-property .carousel-control-next-icon, .visited-property .carousel-control-prev-icon {
  opacity: 1;
}

.variant-table-lg, .variant-table-sm {
  width: 100%;
  border-radius: 4px;
  white-space: nowrap;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #e0e0e0;
}

.variant-table-lg table thead, .variant-table-sm table thead {
  padding: 0;
  width: 100%;
  overflow: hidden;
  height: 70px;
  border-bottom: 1px solid #e0e0e0 !important;
  height: 70px;
  vertical-align: middle;
}

.variant-table-lg .table>:not(:first-child), .variant-table-sm .table>:not(:first-child) {
  border-top: 0;
}

.variant-table-lg tbody tr, .variant-table-sm tbody tr {
  border-top: 1px solid #e0e0e0;
  text-align: left;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}


.header-nav-item {
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.header-nav-item:hover,
.header-nav-item.active-nav-url {
  color: #000 !important;
  font-weight: 700;
}

.header-nav-item:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  left: 50%;
  position: absolute;
  background: #feb302;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.gm-style img:hover {
  background-image: url(./Assets/location-pin-active.png) !important;
  background-size: 100%;
}

.header-nav-item:hover:after,
.header-nav-item.active-nav-url:after {
  width: 20px;
  left: 0;
}

.property-card .prpnx-badge-group {
  top: 10px;
  left: 10px;
}

.property-card .prpnx-badge-group .badge {
  border-radius: 20px !important;
  margin-bottom: 8px;
}

.property-card .prpnx-badge-group .badge+.badge {
  margin-left: 8px;
}

.prop-model .propnx-carousel {
  border-radius: 20px;
  overflow: hidden;
}

.prpnx-search .form-control,
.input-group .form-control {
  box-shadow: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.prpnx-search .input-group-text,
.input-group .input-group-text {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

select,
.form-control {
  border-radius: 10px !important;
}

.gmap-wrapper-parent {
  background-image: url('./Assets/placeholder.jpg');
  object-fit: contain;
  width: 100%;
  background-position: center center;
  height: 100%;
  border-radius: 25px;
}

.gmap-wrapper {
  object-fit: contain;
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 25px;
}


.search-prop #basic-addon2 {
  background-color: #e85a40;

}

.text-link {
  text-decoration: underline;
  cursor: pointer;
  color: #0d6efd;
}


.prop-model .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.nz-option-container ::-webkit-scrollbar-thumb {
  height: 40px;
}

.tidbit .card {
  height: 250px;
  object-fit: contain;
}

.tidbit .card img {
  width: 40%;
  height: auto;
  margin: auto;
}

.tidbit .contents .card {
  border-radius: 20px;
  height: 100%;
  max-height: 300px;
}

.tidbit .tidbit-border {
  background-color: #fff6f5;
  border: 0 !important;
  margin: 10px;
  transition: all 0.3s;
  border: 2px solid #ffe9e6 !important;
  cursor: pointer;
  border-radius: 10px;
}

.tidbit .active-tidbit,
.tidbit .tidbit-border:hover {
  border: 2px solid #e85a40 !important;
}

.aboutbanner {
  background-color: #000000;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./Assets/About/banner.jpg');
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
  background-size: cover;
}

.contactbanner {
  background-color: #000000;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./Assets/Contact/banner.jpg');
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
  background-size: cover;
}

.aboutbanner p {
  font-size: 18px;
}

.nunitoFont {
  font-weight: bolder;
  font-family: 'Nunito', sans-serif !important;
}

.left-side p {
  line-height: 9px;
}

.right-side img {
  border-radius: 20px;
}

.right-side svg {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.why-choose-us-card {
  height: 300px !important;
  max-height: 300px !important;
  transition: all 0.3s;
}

.why-choose-us-card.style--2 {
  height: 260px !important;
}

.why-choose-us-card:hover {
  box-shadow: 0px 18px 7px -14px rgb(0 0 0 / 20%);
}

.why-choose-us-card.card {
  border-radius: 10px;
}

.why-choose-us-card .circle-container:hover {
  background-image: linear-gradient(150deg, #e85a40, #fffa) !important;
}

.why-choose-us-card .circle-container {
  transition: all 0.3s;
}

.circle-container {
  border-radius: 100%;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  border: 2px solid #e85a40;
  z-index: 1;
}

.circle-container svg {
  font-size: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  border-radius: 20px !important;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

.contact-card input:focus, .contact-card textarea:focus {
  border-color: #e85a40;
}

.property-price-card {
  color: #1a2258;
}

.gotomap {
  font-weight: bolder;
  font-size: 16px;
  color: #216bff;
}

.contact-card input:not([type=checkbox]):not([type=radio]), .contact-card textarea, .contact-card select {
  height: 44px;
  border-color: #cdcdcd;
  box-shadow: none !important;
}

.contact-card .form-check-input:checked {
  background-color: #e85a40;
  border-color: #e85a40;
}

.contact-card select {
  appearance: auto;
}

textarea {
  resize: none !important;
  height: 120px !important;
}

.contact-card .addon {
  border-color: #e85a40;
  background-color: transparent;
}

.contact-card .addon svg {
  color: #e85a40;
}



.h50 {
  height: 50px;
}

.contact-card h2::before {
  content: '\00a0';
  background-color: #e85a40;
  height: 20px;
  width: 10px;
  margin-right: 10px;
}

.gototop-icon svg {
  bottom: 20px;
  left: 20px;
  cursor: pointer;

}

.footer_one {
  background-color: #1f2123;
}

.footer_one h4 {
  color: #727981;
  font-size: 18px;
  font-weight: 900;
}

.footer_one a {
  color: #fff;
  text-decoration: none;
}

.footer_one a:hover {
  color: #feb302;
}

.property-card .card-text {
  font-size: 12px;
  color: #919191;
  font-weight: 500;
}

.service {
  background-color: #e85a40 !important;
  border: 0 !important;
  box-shadow: none !important;
}

body .h1, body .h2, body .h3, body .h4, body .h5, body .h6, body h1, body h2, body h3, body h4, body h5, body h6 {
  font-weight: 700;
}

/* .property-name{
  top: 15% !important;
  left: 15% !important;
} */
.variant-table-sm {
  display: none;
}

.sm-first-row {
  color: #e85a40 !important;
}

.carousel-indicators {
  z-index: 5;
}

.price-range p,
.sale-range p {
  font-size: 18px;
  font-weight: 800;
  color: #e16041;
}

.swiper-slide img {
  max-height: 370px;
}

.prpnx-swiper-detail .swiper-slide {
  width: auto;
}

.prpnx-swiper-detail .swiper-button-prev, .prpnx-swiper-detail .swiper-button-next {
  background-color: #fff;
  color: #e85d49;
}

.prpnx-swiper-detail .swiper-button-prev:after, .prpnx-swiper-detail .swiper-button-next:after {
  font-size: 25px;
}

.prpnx-swiper-detail .swiper-button-prev {
  left: 0px;
  border-radius: 0px 70px 70px 0px;
  padding: 32px 20px 32px 16px;
}

.prpnx-swiper-detail .swiper-button-next {
  right: 0px;
  border-radius: 70px 0px 0px 70px;
  padding: 32px 16px 32px 20px;
}

.prpnx-swiper-detail .swiper-pagination-bullet {
  background-color: #fff;
}

.prpnx-title-area {
  padding: 50px 0px;
}

.prpnx-footer {
  padding: 64px 0px 20px;
  background-color: #111;
  margin-top: 60px;
  color: #999;
}

footer.prpnx-footer {}

.prpnx-footer h4 {
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
}

.prpnx-footer-bottom {
  font-size: 13px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #2b2b2b;
}

.prpnx-footer-bottom .col-sm-6:last-child {
  text-align: right;
}

.footer_about_widget img.logo {
  max-width: 200px;
  filter: contrast(0) brightness(2.4);
  margin-bottom: 20px;
}

.prpnx-sec-linklist {
  padding: 0;
  list-style: none;
}

.prpnx-sec-linklist a, .prpnx-sec-link {
  color: inherit;
  text-decoration: none;
  position: relative;
}

.prpnx-sec-linklist a:hover, .prpnx-sec-link:hover {
  color: #fff;
}

.prpnx-sec-linklist a:after, .prpnx-sec-link:after {
  display: block;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  border-bottom: 1px solid #fff;
  content: "";
  transition: transform .6s cubic-bezier(.28, .75, .22, .95);
  transform: scaleX(0);
  transform-origin: right center;
}

.prpnx-sec-linklist a:hover:after, .prpnx-sec-link:hover:after {
  transform: scale(1);
  transform-origin: left center;
}

.prpnx-sec-linklist li {
  margin-bottom: 9px;
}

.gototop-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #e85a40;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
}

.gototop-icon svg {

  margin-top: -3px;
}


.gototop-icon svg path {
  fill: #fff;
}


.gototop-icon:hover {
  background-color: #fff;
  transition: all 0.3s
}

.gototop-icon:hover svg path {
  fill: #e85a40;
}

.aboutbanner .py-5 {
  position: relative;
  z-index: 9;
}

section.aboutbanner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000063;
  pointer-events: none;
}


@media screen and (max-width:768px) {
  .why-choose-us-card.card {
    margin: 0px 0px 20px !important;
  }

  footer.footer_one+.bg-dark {
    padding: 16px;
  }

  .gototop-icon {
    display: none;
  }

  .property-card.card img {
    height: 120px;
  }

  .variant-table-lg {
    display: none;
  }

  .variant-table-sm {
    display: block !important;
  }

  ._1g3wy {
    margin: 0 0 24px;
    box-shadow: none;
    border: 1px solid #dddee6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
    position: unset;
  }

  .all-properties {
    margin: 0px 0px;
    height: auto;
    overflow: visible;
    padding: 0px;
  }

  .property-card.card {
    max-width: none;
  }

  .prpnx-home-map.gmap-wrapper {
    /* display: none; */
  }

  footer.footer_one {
    padding-left: 10px;
    padding-right: 10px;
  }

  .aboutbanner {
    background-image: url('./Assets/About/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
  }

  .aboutbanner .py-5 {
    padding: 30px !important;
    position: relative;
    z-index: 9;
  }

  section.aboutbanner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000063;
    pointer-events: none;
  }


  .tidbit .contents .card {
    overflow-y: scroll;
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: url('./Assets/minus.png') !important;
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url('./Assets/plus.png') !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}

button {
  box-shadow: none !important;
}



.link {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.link:hover:after {
  width: 100%;
  left: 0;
}

.contact-form .card {
  margin-top: 0;
  margin-bottom: 0;
  padding: 30px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.propnx-carousel {
  position: relative;
}

.propnx-carousel .carousel-item::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, .18) 16.31%, transparent 37.79%), linear-gradient(1turn, rgba(0, 0, 0, 0.8), transparent 33.5%);
  pointer-events: none;
  z-index: 2;
}

.propnx-carousel .carousel-caption {
  bottom: 30px;
  top: inherit;
  z-index: 4;
  left: 30px;
  right: initial;
  text-shadow: 0px 3px 5px rgb(0 0 0 / 50%);
}

.propnx-carousel .carousel-caption h1 {
  font-weight: 700;
  font-size: 44px;
}

.visited-property h3,
.visited-property .h3,
.visited-property .card-body h4 {
  font-size: 16px;
}

.propnx-carousel .container {
  position: relative;
}

.variant-table-sm th, .variant-table-sm td {
  font-size: 12px;
}

.carousel-caption p {
  font-size: 22px;
  font-weight: 500;
}

.mortar-wrapper {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  text-align: center;
  padding: 30px 20px;
  margin: 0.5em 0px;
}

.mortar-wrapper .scheduleTourContactTitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.mortar-wrapper .btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.mortar-wrapper .requestTourBtn.btn {
  background-color: #e85a40;
  border-color: #e85a40;
}

.mortar-wrapper .sendMessageBtn.btn {
  background-color: #25d366;
  border-color: #25d366;
}

.todaysHours {
  margin-bottom: 0px;
}

.all-properties .propnx-carousel .carousel-item::after,
.property-card .carousel-item::after {
  display: none;
}

.mortar-wrapper .btn svg path {
  fill: #fff;
}

.mortar-wrapper .btn svg {
  margin-right: 5px;
}

.clear-filter {
  font-size: 14px;
  display: block;
  text-align: right;
  padding: 5px 8px 0px;
  cursor: pointer;
  transition: all 0.3s;
}

.total-records {
  font-size: 14px;
  display: block;
  padding: 5px 8px 0px;
  transition: all 0.3s;
}

.clear-filter svg {
  width: 12px;
  height: 12px;
}

.clear-filter:hover {
  color: #6a9ac1;
}

span.no-data {
  display: flex;
  width: 100%;
  min-height: 230px;
  background-color: #efefef;
  border-radius: 10px;
  border: 1px solid #d4d9de;
  justify-content: center;
  align-items: center;
  color: #747474;
  font-weight: 700;
  font-size: 18px;
}

.navbar .nav-link {}

.navbar .nav-link svg {
  padding: 6px;
  width: 30px;
  height: 30px;
  background: #d9543c;
  color: #fff;
  border-radius: 40px;
  transition: all 0.3s;
}

.navbar .nav-link:hover svg {
  background: #333;
}

.ms-auto.navbar-nav {
  align-items: center;
}

.contact-card .service.btn-primary {
  border-radius: 10px !important;
  padding: 10px 30px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.contact-card .service.btn-primary:hover {
  background-color: #ff836d !important;
}

.contact-form .card h2 {
  font-size: 20px;
}

.btn {
  position: relative;
}

.loadersmall {
  border: 3px solid rgba(0, 0, 0, 0.129);
  animation: spin 1s linear infinite;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px
}

.loaderlarge {
  border: 6px solid rgba(0, 0, 0, 0.129);
  animation: spin 1s linear infinite;
  border-top: 6px solid #fff;
  border-radius: 50%;
  width: 52px;
  height: 52px
}

.btn .loader-wrapper {
  z-index: 99;
  position: absolute;
  width: 26px;
  height: 26px;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  opacity: 0;
  pointer-events: none
}

.btn.is--loading {
  pointer-events: none
}

.btn.is--loading .loader-wrapper {
  opacity: 1
}

.btn.is--loading .btn-content {
  opacity: 0
}

.accordion-header .accordion-button:not(.collapsed) {
  color: #e5593f;
  background-color: #fdf6f5;
}

h2.accordion-header button {
  font-weight: 700;
}

.property-card .card-body {
  padding: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}

@media screen and (max-width:991px) {
  .contact-form .card {
    padding: 1rem;
  }

  .why-choose-us-card {
    margin: 10px !important;
  }
}

@media screen and (max-width:300px) {
  .prop-nax-header .logo {
    width: 150px;
    height: 45px;
  }
}

@media screen and (max-width: 991px) {
  .all-properties {
      margin: 0px 0px;
      height: auto;
      overflow: visible;
      padding: 0px;
  }
}